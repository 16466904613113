<template>
  <div class="row">
    <div class="col-xl-12">
      <div id="panel-1" class="panel">
        <div class="panel-container show">
          <div class="panel-content">
            <el-row :gutter="10">
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="4">
                <el-input type="text" size="medium" v-model="form.name" class=" mt-3"
                          placeholder="Tìm tên, email, sđt nhân sự" autocomplete="false"></el-input>
              </el-col>
              <el-col v-if="isHCKVOrGDKV" :xs="24" :sm="24" :md="8" :lg="4" :xl="3">
                <el-select clearable v-model="form.branch_id" filterable class="w-100 mt-3"
                           placeholder="Chọn văn phòng để xem">
                  <el-option
                      v-for="item in branches"
                      :key="item.id"
                      :label="item.name_alias ? item.name_alias : item.name"
                      placeholder="Chọn cơ sở"
                      :value="item.id">
                    <span style="float: left">{{ item.name_alias ? item.name_alias : item.name }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                <el-select v-model="form.status" clearable class="w-100 mt-3" placeholder="Chọn trạng thái làm việc">
                  <el-option
                      v-for="item in optionStatus"
                      :key="item.id"
                      :label="item.value"
                      :value="item.id">
                    <span style="float: left">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                <el-select v-model="form.account_type"
                           clearable class="w-100 mt-3"
                           placeholder="Chọn Vị trí"
                           @change="handleAccountType"
                >
                  <el-option
                      v-for="item in optionAccountType"
                      :key="item.name"
                      :label="item.value"
                      :value="item.name">
                    <span style="float: left">{{ item.value }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3" v-if="is_show_select_level">
                <el-select v-model="form.business_level_id"
                           clearable
                           class="w-100 mt-3"
                           placeholder="Cấp bậc"
                >
                  <el-option
                      v-for="item in businessLevels"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" :sm="12" :md="8" :lg="4" :xl="3">
                <el-select v-model="form.plurality" clearable class="w-100 mt-3" placeholder="Chọn vị trí kiêm nhiệm">
                  <el-option
                      v-for="item in kiemNghiemOption"
                      :key="item.id"
                      :label="item.name"
                      :value="item.id">
                    <span style="float: left">{{ item.name }}</span>
                  </el-option>
                </el-select>
              </el-col>
              <el-col :xs="24" class="mt-3" :sm="12" :md="8" :lg="4" :xl="4">
                <el-date-picker
                    class="w-100"
                    v-model="form.startDate"
                    type="date"
                    format="yyyy-MM-dd"
                    value-format="yyyy-MM-dd"
                    @change="pickStart"
                    :picker-options="pickerStartOptions"
                    placeholder="Ngày bắt đầu làm việc(Từ ngày)">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" class="mt-3" :sm="12" :md="8" :lg="4" :xl="3">
                <el-date-picker
                    class="w-100"
                    v-model="form.endDate"
                    type="date"
                    ref="picker"
                    :onPick="pickEnd"
                    format="yyyy-MM-dd"
                    @change="pickEnd"
                    value-format="yyyy-MM-dd"
                    :picker-options="pickerEndOptions"
                    placeholder="Đến ngày">
                </el-date-picker>
              </el-col>
              <el-col :xs="24" :sm="24" :md="8" :lg="3" :xl="2">
                <button :disabled="loading.search"
                        class="btn btn-primary w-100 mt-3"
                        @click="find">
                  <i v-bind:class="[loading.search ? 'el-icon-loading' : 'el-icon-search']"></i> Tìm kiếm
                </button>
              </el-col>
            </el-row>
            <el-table
                :data="tableData"
                stripe
                v-loading="loading.table"
                border
                class="table-hover mb-5 mt-5 table-responsive-lg"
                style="width: 100%">
              <el-table-column label="#" type="index" width="50" :index="indexMethod">
              </el-table-column>
              <el-table-column
                  label="Mã nhân sự"
                  width="100">
                <template v-slot="scope">
                  {{ scope.row.id + 100000 }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Họ và tên"
                  width="180">
                <!--eslint-disable-->
                <template slot-scope="scope">
                  {{ scope.row.name }}
                  <el-tooltip v-if="scope.row.profile.status_verify === 2 || scope.row.profile.status_verify === 3"
                              :content="coverVerify(scope.row)" placement="top">
                    <a href="javascript:void(0);"
                       class="btn btn-xss btn-icon rounded-circle" :class="{
                                            'btn-warning': scope.row.profile.status_verify === 3,
                                            'btn-success': scope.row.profile.status_verify === 2,
                                           }">
                      <i class="fal fa-check" :class="{
                                            'fa-times': scope.row.profile.status_verify === 3,
                                            'fa-check': scope.row.profile.status_verify === 2,
                                           }"></i>
                    </a>
                  </el-tooltip>
                </template>
              </el-table-column>
              <el-table-column
                  prop="account_type"
                  label="Vị trí">
                <template slot-scope="scope">
                  {{ scope.row.profile.account_type.name }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Cấp bậc"
                  width="80">
                <template slot-scope="scope">
                  {{ scope.row.profile.business_level_id > 0 ? scope.row.profile.business_level.name : '' }}
                </template>
              </el-table-column>
              <el-table-column
                  prop="plurality"
                  label="Kiêm nhiệm">
                <template slot-scope="scope">
                              <span class="badge badge-pill badge-success mt-1"
                                    v-if="scope.row.user_positions.length > 0"
                                    v-for="(i , k) in scope.row.user_positions"
                                    :key="k">
                                {{ i.position }}
                              </span>
                </template>
              </el-table-column>
              <el-table-column
                  width="110"
                  label="Trạng thái"
                  class="">
                <template slot-scope="scope">
                  <el-tag :type="scope.row.status === 1 ? 'success' : 'danger'"
                          disable-transitions>{{ scope.row.status === 1 ? 'Hoạt động' : 'Đã nghỉ việc' }}
                  </el-tag>
                </template>
              </el-table-column>
              <el-table-column label="Cấp trên">
                <template slot-scope="scope">
                  {{ scope.row.parent ? scope.row.parent.name : '' }}
                </template>
              </el-table-column>
              <el-table-column label="Mã cấp trên">
                <template slot-scope="scope">
                  {{ scope.row.parent_id ? (scope.row.parent_id + 100000) : '' }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Số điện thoại">
                <template slot-scope="scope">
                  {{ scope.row.phone }}
                </template>
              </el-table-column>
              <el-table-column
                  label="Ngày bắt đầu">
                <template slot-scope="scope">
                  <span style="margin-left: 10px" v-if="scope.row.profile.start_to_work">{{
                      $moment(scope.row.profile.start_to_work, 'YYYY-MM-DD').format('DD-MM-YYYY')
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column
                  label="Ngày thôi việc">
                <template slot-scope="scope">
                  <span style="margin-left: 10px" v-if="scope.row.profile.thoi_gian_nghi_viec">{{
                      $moment(scope.row.profile.thoi_gian_nghi_viec).format('DD-MM-YYYY')
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="isHCKVOrGDKV"
                               label="Văn phòng">
                <template slot-scope="scope">
                  {{
                    scope.row.profile.branch.name_alias ? scope.row.profile.branch.name_alias : scope.row.profile.branch.name
                  }}
                </template>
              </el-table-column>
              <el-table-column
                  width="150"
                  label="Thao tác" v-if="isTPNSorHCKV">
                <template slot-scope="scope">
                  <el-row class="action-table">
                    <el-tooltip content="Sửa thông tin nhân sự" placement="top">
                      <a @click="handleEdit(scope.$index, scope.row)"
                         class="btn btn-primary btn-sm btn-icon waves-effect waves-themed">
                        <i class="fal fa-edit"></i>
                      </a>
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.status === 1" content="Cho nhân viên nghỉ việc" placement="top">
                      <a class="btn btn-danger btn-sm btn-icon waves-effect waves-themed"
                         @click="dismissal(scope.$index)">
                        <i class="el-icon-remove-outline"></i>
                      </a>
                    </el-tooltip>
                    <el-tooltip v-if="scope.row.status !== 1" content="Để nhân viên hoạt động trở lại" placement="top">
                      <a class="btn btn-info btn-sm btn-icon waves-effect waves-themed"
                         @click="activeAccount(scope.$index, scope.row)">
                        <i class="el-icon-ship"></i>
                      </a>
                    </el-tooltip>
                    <el-tooltip :content="quyenPhongVan(scope.row) ? 'Hủy quyền phỏng vấn' : 'Cấp quyền phỏng vấn'"
                                placement="top">
                      <a @click="activeOrDismissQPV(scope.row, scope.$index)"
                         class="btn-sm btn-icon btn waves-effect waves-themed"
                         :class="quyenPhongVan(scope.row) ? 'btn-warning' : 'btn-success'">
                        <i :class="quyenPhongVan(scope.row) ? 'fas fa-times-circle' : 'fal fa-check'"></i>
                      </a>
                    </el-tooltip>
                  </el-row>
                </template>
              </el-table-column>
            </el-table>
            <div class="edutalk-paging">
              <div class="block">
                <el-pagination
                    background
                    @current-change="handleCurrentChange"
                    :current-page.sync="paging.current_page"
                    :page-size="paging.per_page"
                    layout="prev, pager, next"
                    :total="paging.total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
        :title="dialog.title"
        class="responsive"
        :visible.sync="dialogFormVisible"
        width="80%">
      <el-row :gutter="10" class="pr-2 pl-2">
        <human-item
            @completed="completedUpdate"
            @close="closeDialog"
            v-if="humanResource"
            :data="humanResource"
            :update="isUpdate"
            :businessLevels="businessLevelByAccountType"
            :confirm="isConfirm"></human-item>
      </el-row>
    </el-dialog>
    <!--   Modal nhân sự nghỉ việc   -->
    <el-dialog :title="dialog.title" class="responsive w-30" :visible.sync="dialogFormDismissal">
      <el-row :gutter="10" class="pr-2 pl-2">
        <h5>Bạn có muốn cho nhân sự: <b>{{ humanResource.name }}</b></h5>
        <h5>Mã nhân sự: <b>{{ humanResource.id + 100000 }}</b></h5>
        <div class="form-row">
          <div class="col-md-12 mt-3">
            <label class="form-label">Thôi việc từ ngày</label>
            <ValidationProvider vid="date-end" name="Ngày nghỉ việc" rules="required"
                                v-slot="{ errors,classes }">
              <el-date-picker
                  class="w-100"
                  v-model="form.endDateLeave"
                  type="date"
                  ref="picker"
                  :onPick="pickEnd"
                  format="yyyy-MM-dd"
                  @change="pickEnd"
                  value-format="yyyy-MM-dd"
                  :picker-options="pickerEndLeaveOptions"
                  placeholder="yyyy-MM-dd">
              </el-date-picker>
              <div class="fv-plugins-message-container">
                <div data-field="date-end" data-validator="notEmpty" class="fv-help-block">
                  {{ errors[0] }}
                </div>
              </div>
            </ValidationProvider>
          </div>
          <div class="col-md-12 mt-3">
            <input v-model="form.reason_lay_off" disabled type="text" class="form-control" id="address"
                   placeholder="Nhập địa chỉ thường trú">
          </div>
        </div>
        <button :disabled="loading.save || !form.endDateLeave" @click="dismissalOrActiveQuery(
                    {
                        id: humanResource.id,
                        status: 5,
                        index: humanResource.index
                    }
                    )" class="btn btn-primary float-right mt-5" type="submit">
          <i v-if="loading.save" class="el-icon-loading"></i>
          Đồng ý cho nghỉ
        </button>
        <button class="btn btn-outline-default float-right mt-5 mr-3" @click="dialogFormDismissal = false"> Hủy</button>
      </el-row>
    </el-dialog>
  </div>
</template>

<script>
import {accountTypeOption, kiemNghiemOption} from "@/core/config/accountTypeOption";
import {
  HANH_CHINH_KHU_VUC,
  GDKV,
  GDVP,
  GDVPorGDKV,
  SALE_LEADER,
  SALE_EXPERT,
  SALE_MEMBER,
  SALE_INTERN
} from "@/core/config/accountTypeOption";
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import {USER_HUMAN_RESOURCE, UPDATE_DISMISSAL_ACTIVE, EXPORT_EXCEL} from "@/core/services/store/profile.module";
import {BRANCH_LIST} from "@/core/services/store/service.module";
import HumanItem from "@/views/pages/human-resource/chi-tiet-nhan-su";
import {mapGetters} from "vuex";

import lodash from 'lodash-es';
import deepdash from 'deepdash-es';
import {UPDATE_QPV} from "../../../core/services/store/profile.module";
import {GET_BUSINESS_LEVEL} from "../../../core/services/store/business/level.module";

const _ = deepdash(lodash);

export default {
  name: "nhan-su-hoat-dong",
  components: {
    HumanItem
  },
  data() {
    return {
      businessLevels: [],
      businessLevelByAccountType: [],
      form: {
        name: null,
        branch_id: null,
        status: null,
        account_type: null,
        plurality: null,
        month: null,
        reason_lay_off: null,
        startDate: null,
        endDate: null,
        endDateLeave: null,
        business_level_id: null
      },
      loading: {
        table: false,
        search: false,
        save: false,
      },
      tableData: [],
      branches: [],
      paging: {
        current_page: 1,
        per_page: 10,
        total: 0,
      },
      optionStatus: [
        {id: 'active', value: 'Đang làm việc'},
        {id: 'unActive', value: 'Đã nghỉ việc'}
      ],
      optionAccountType: accountTypeOption,
      kiemNghiemOption: kiemNghiemOption,
      humanResource: [],
      optionMonth: [],
      dialogFormVisible: false,
      dialogFormDismissal: false,
      dialog: {
        title: null,
        isEdit: false,
        isVerify: false,
      },
      isUpdate: false,
      is_show_select_level: false,
      isConfirm: false,
      isHCKVOrGDKV: false,
      canExport: false,
      HANH_CHINH_KHU_VUC,
      GDKV,
      GDVP,
      GDVPorGDKV,
      /* date range picker*/
      currentDate: new Date(),
      pickerStartOptions: {
        disabledDate: this.disabledStartDate
      },
      pickerEndOptions: {
        disabledDate: this.disabledEndDate
      },
      pickerEndLeaveOptions: {
        disabledDate: this.disabledLeaveEndDate
      }
    }
  },
  created() {
    if ((this.currentUser.profile.khuvuc_id > 0 && this.currentUser.profile.account_type.id === this.GDKV)
        || (this.currentUser.profile.khuvuc_id > 0 && this.currentUser.profile.account_type.id === this.HANH_CHINH_KHU_VUC)) {
      this.isHCKVOrGDKV = true;
      if (this.checkExitsObject()) {
        this.optionAccountType.push({name: HANH_CHINH_KHU_VUC, value: 'Hành chính khu vực'});
      }
    }
    this.canExport = this.isHCKVOrGDKV ||
        this.currentUser.user_positions.find((item) => {
          return item.position === 'TPNS';
        }) || this.currentUser.profile.account_type.id === this.GDVP;
  },
  computed: {
    ...mapGetters(['currentUser']),
    isTPNSorHCKV() {
      let accountTypeName = this.currentUser?.profile?.account_type ? this.currentUser.profile.account_type.slug : this.currentUser.account_type.slug;
      return _.indexOf(['hckv'], accountTypeName) > -1 ||
          _.findIndex(this.currentUser.user_positions, function (item) {
            return item.position === 'TPNS';
          }) > -1
    }
  },
  mounted() {
    this.setDefault();
  },
  methods: {
    setDefault() {
      if (this.isHCKVOrGDKV) {
        this.getCenter();
      } else {
        this.querySever();
      }
      let area = this.isHCKVOrGDKV ? `: ${this.currentUser.profile.area.name}` : '';
      let payload = [
        {title: "Danh sách"},
        {title: "Danh sách nhân sự" + area, icon: 'far fa-user'},
      ];
      if (this.canExport) {
        payload.push([{text: 'Export excel', 'cb': this.exportExcel, 'class': 'btn btn-success float-right'}]);
      }
      this.$store.dispatch(SET_BREADCRUMB, payload);
    },
    checkExitsObject() {
      for (let i = 0; i < this.optionAccountType.length; i++) {
        if (this.optionAccountType[i].name === HANH_CHINH_KHU_VUC) {
          return false;
        }
      }
      return true;
    },
    getBusinessLevelByAccountType(item) {
      if (item.user_boss) {
        this.$store.dispatch(GET_BUSINESS_LEVEL, {
          limit: 10000,
          status: 1,
          account_type_id: item.user_boss.profile.account_type_id,
          user_id: item.id,
        }).then((res) => {
          if (!res.error) {
            this.businessLevelByAccountType = res.data.data
          }
        }).catch((e) => {
          console.log(e, 'error')
        });
      }
    },
    getBusinessLevels() {
      this.$store.dispatch(GET_BUSINESS_LEVEL, {
        limit: 10000,
        status: 1,
        account_type_id: this.form.account_type
      }).then((res) => {
        if (!res.error) {
          this.businessLevels = res.data.data
        }
      }).catch((e) => {
        console.log(e, 'error')
      });
    },
    closeDialog() {
      this.dialogFormVisible = false;
    },
    completedUpdate(item) {
      let data = this.tableData.find((user) => {
        return user.id === item.id;
      });

      if (item.profile.status_verify) {
        data.profile.status_verify = item.profile.status_verify
      }
      if (item.profile.start_to_work) {
        data.profile.start_to_work = item.profile.start_to_work;
      }
      if (item.profile.address) {
        data.profile.address = item.profile.address;
      }
      if (item.name) {
        data.name = item.name;
      }
      data.parent_id = item.parent_id;
      data.parent = item.user_boss;
      data.user_positions = item.user_positions;
      this.dialogFormVisible = false
      this.$message({type: 'success', message: `Cập nhật thành công`, showClose: true});
    },
    activeAccount(index, item) {
      const h = this.$createElement;
      this.$msgbox({
        title: 'Thông báo',
        message: h('p', null, [
          h('span', null, `Bạn có muốn cho nhân sự: `),
          h('span', {style: 'color: teal'}, `${item.name}`),
          h('br', null, null),
          h('span', null, `Mã nhân sự: `),
          h('span', {style: 'color: teal'}, `${item.id + 100000}`),
          h('br', null, null),
          h('span', null, `Hoạt động trở lại với Edutalk`)
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: 'Tiếp tục',
        cancelButtonText: 'Hủy',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            this.dismissalOrActiveQuery({id: item.id, status: 1, index: index});
          }
          done();
        }
      })
    },
    dismissal(index) {
      this.form.endDateLeave = this.$moment().format('YYYY-MM-DD');
      this.dialog.title = `Thông báo`;
      this.dialogFormDismissal = true;
      this.humanResource = this.tableData[index];
      this.humanResource.index = index;
      this.optionMonth = [this.$moment().subtract('1', 'months').format('MM-YYYY'), this.$moment().format('MM-YYYY')];
      this.form.month = this.$moment().format('MM-YYYY')
      this.form.reason_lay_off = `OUT ${this.humanResource.profile.account_type.name}`
    },

    handleCurrentChange(val) {
      this.querySever({page: val});
    },
    indexMethod(index) {
      return 10 * (this.paging.current_page - 1) + (index + 1);
    },
    coverVerify(item) {
      if (item.profile.status_verify === 2) {
        return 'Đã xác minh danh tính'
      }
      if (item.profile.status_verify === 3) {
        return 'Đã bị từ chối xác minh'
      }
      return ''
    },
    find() {
      this.paging.current_page = 1;
      this.querySever();
    },
    handleEdit(index, item) {
      this.getBusinessLevelByAccountType(item);
      this.dialog.title = `Sửa thông tin nhân sự: ${item.name}`;
      this.dialogFormVisible = true;
      this.humanResource = this.tableData[index];
      this.isConfirm = false;
      this.isUpdate = true;
      this.humanResource.index = index;
    },
    handleConfirm(index, item) {
      this.dialog.title = `Thông tin xác minh nhân sự: ${item.name}`;
      this.dialogFormVisible = true;
      this.isConfirm = true;
      this.isUpdate = false;
      this.humanResource = this.tableData[index];
      this.humanResource.index = index;
    },
    getCenter() {
      this.$store.dispatch(BRANCH_LIST)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.branches = response.data;
              this.form.branch_id = response.data.length > 0 ? response.data[0].id : 0;
              this.querySever({branch_id: this.form.branch_id});
            }
          })
    },
    querySever(customProperties) {
      this.loading.table = true;
      this.loading.search = true;
      let params = this.mergeParams(customProperties);
      this.currentPage = 1;
      this.$store.dispatch(USER_HUMAN_RESOURCE, params)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {
              this.tableData = response.data.data;
              this.paging = response.data.meta.pagination;
            }
            this.loading.table = false;
            this.loading.search = false;
          })
    },
    mergeParams(customProperties) {
      let params = {
        page: this.paging.current_page,
        per_page: this.paging.per_page,
      };
      if (this.form.name) {
        params = _.merge(params, {key_word: this.form.name})
      }
      if (this.form.account_type) {
        params = _.merge(params, {account_type: this.form.account_type})
      }
      if (this.form.branch_id) {
        params = _.merge(params, {branch_id: this.form.branch_id})
        params = _.merge(params, {branch_id: this.form.branch_id})
      }
      if (this.form.status) {
        params = _.merge(params, {status: this.form.status})
      }
      if (this.form.plurality) {
        params = _.merge(params, {plurality: this.form.plurality})
      }
      if (this.form.startDate) {
        params = _.merge(params, {startDate: this.form.startDate})
      }
      if (this.form.endDate) {
        params = _.merge(params, {endDate: this.form.endDate})
      }
      if (this.form.business_level_id) {
        params = _.merge(params, {business_level_id: this.form.business_level_id})
      }
      params = _.merge(params, {not_candidate: true})
      params = _.merge(params, customProperties);
      return params;
    },
    dismissalOrActiveQuery(customProperties) {
      let params = {};
      this.loading.save = true;
      params = _.merge(params, customProperties);
      if (this.form.endDateLeave) {
        params = _.merge(params, {month_day: this.form.endDateLeave});
      }
      if (this.form.reason_lay_off) {
        params = _.merge(params, {reason_lay_off: this.form.reason_lay_off});
      }
      params = _.merge(params, {});
      this.$store.dispatch(UPDATE_DISMISSAL_ACTIVE, params)
          .then((response) => {
            if (response.status === 422) {
              this.$message({type: 'warning', message: `${response.message}`, showClose: true});
            } else {

              let data = this.tableData[customProperties.index];
              data.status = response.data.status;
              data.profile.thoi_gian_nghi_viec = response.data.profile.thoi_gian_nghi_viec;
              //re-update data table because el table don't update data
              //this.tableData = this.tableData.slice(0);
              this.dialogFormDismissal = false;
              this.$message({
                type: 'success',
                message: response.data.status === 2 ? 'Cho nhân sự nghỉ việc thành công!' : 'Đã cho nhân sự hoạt động trở lại!',
                showClose: true
              });
            }
          }).catch((error) => {
        this.$notify({
          title: 'Thất bại',
          message: error.data.message,
          type: 'error'
        });
      }).finally(() => {
        this.loading.save = false;
      });
    },

    exportExcel() {
      let params = this.mergeParams();
      this.$store.dispatch(EXPORT_EXCEL, params);
    },
    /* date picker methods */
    pickStart(date) {
      this.fromDate = null;
      if (date) {
        this.fromDate = new Date(date);
      }
    },
    pickEnd(date) {
      this.toDate = null;
      if (date) {
        this.toDate = new Date(date);
      }
    },
    disabledStartDate(date) {
      if (this.toDate) {
        return this.toDate < date
      }
      return date > new Date();
    },
    disabledEndDate(date) {
      if (this.fromDate) {
        return this.fromDate > date || date > new Date();
      }
      return date > new Date();
    },
    disabledLeaveEndDate(date) {
      this.fromDateLeave = new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1);
      this.fromDateStart = new Date(this.$moment(this.humanResource.profile.start_to_work).format('YYYY'),
          this.$moment(this.humanResource.profile.start_to_work).format('MM') - 1,
          this.$moment(this.humanResource.profile.start_to_work).format('DD'));
      if (this.fromDateLeave || this.fromDateStart) {
        return this.fromDateLeave > date || date > new Date() || this.fromDateStart > date;
      }
      return date > new Date();
    },
    quyenPhongVan(user, index) {
      return user.profile.quyen_phong_van === 1;
    },
    activeOrDismissQPV(user, index) {
      const h = this.$createElement;
      let quyenPhongVan = user.profile.quyen_phong_van === 1 ? 0 : 1;
      let customText = user.profile.quyen_phong_van === 1 ? 'hủy' : 'cấp';
      this.$msgbox({
        title: 'Thông báo',
        message: h('p', null, [
          h('span', null, `Bạn có muốn ${customText} quyền phỏng vấn cho nhân sự: `),
          h('span', {style: 'color: teal'}, `${user.name}`),
          h('br', null, null),
          h('br', null, null),
        ]),
        showCancelButton: true,
        showClose: false,
        confirmButtonText: 'Tiếp tục',
        cancelButtonText: 'Hủy',
        beforeClose: (action, instance, done) => {
          if (action === 'confirm') {
            let params = {id: user.id, quyen_phong_van: quyenPhongVan};
            this.$store.dispatch(UPDATE_QPV, params).then((response) => {
              let data = this.tableData[index];
              data.profile.quyen_phong_van = response.data.profile.quyen_phong_van;
            });
          }
          done();
        }
      })
    },
    handleAccountType() {
      this.is_show_select_level = false;
      this.form.business_level_id = null;
      this.businessLevels = [];
      if ([SALE_LEADER, SALE_EXPERT, SALE_MEMBER, GDVP, GDKV].includes(this.form.account_type)) {
        this.getBusinessLevels();
        this.is_show_select_level = true;
      }
    }
  },
}
</script>
